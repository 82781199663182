/* body {
    font-family: 'Muli', 'Rosario', 'Raleway', 'Merriweather';
} */

/* .navbar-brand {
    text-transform: uppercase;
} */

/* .reveal::after {
    content: '\f270';
    font-weight: lighter;
} */


/* .hero-banner {
    background-image: url('./assets/img/negril-bar.jpg');
}
.blurry-layer {
    background-color: rgba(0,0,0,.5);
} */

/* header {
    border-bottom: 1px solid #efefef;
}

@media (min-width: 576px) { 
    .navbar.shadow {
        box-shadow: none !important;
    }
}

@media (max-width: 991px) { 
    .filter-bar .form-group {
        margin-bottom: 1.5rem;
    }
}

.container-vertical {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 100px);
}

.container-vertical-content {
  flex-grow: 1;
} */
  
@media (min-width: 992px) {
    .w-lg-15 { width: 15%; }
    .w-lg-30 { width: 30%; }
}

@media (min-width: 1200px) {
    .w-xl-60 { width: 60%; }
}

.btn-chevron i {
    position: relative;
    top: -1px
}
.btn-pointer {
    cursor: default !important;
}
.disabled span {
    opacity: .5;
}